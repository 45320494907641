@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #edf2f7 !important;
  scrollbar-width: none;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button.MuiButton-root, .active .page-link {
  background-color: #5FD4D0 !important;
  color: #FFF !important;
  border:none !important;
}
button.MuiButton-root.Mui-disabled {
  opacity: 0.4;
}
button.MuiButton-root:hover .active .page-link:hover {
  background-color: #5FD4D0 !important;
  color: #FFF !important;
  border:none !important;
  opacity: 0.8 !important;
}

label.Mui-focused {
  color:#5FD4D0 !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color:#5FD4D0 !important;
}

.Mui-selected {
  outline: none !important;
  color:#5FD4D0 !important;
}
.MuiTabs-indicator {
  background-color:#5FD4D0 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiDialog-paper {
  max-width: 1000px !important;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  width: 0px;
}

::-webkit-scrollbar-track-piece {
  width: 0px;
}

::-webkit-scrollbar-corner {
  width: 0px;
}

::-webkit-scrollbar:horizontal {
  display: none;
}